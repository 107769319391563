// https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
import { useLayoutEffect, useCallback, useRef } from 'react'
import { throttle } from 'lodash'

const isBrowser = typeof window !== `undefined`

function getScrollPosition(element) {
  if (!isBrowser) return 0
 
  if (!element) return window.scrollY

  const target = element.current || document.body
  const position = target.getBoundingClientRect()
  return position.top
}

export default function useScrollPosition(effect, deps, element) {
  if (!isBrowser) return
  const previous = useRef(0)
  const callBack = useCallback(throttle(() => {
    const current = getScrollPosition(element)
    effect({current, previous:previous.current})
    previous.current = current
  }, 500), deps)

  useLayoutEffect(() => {
    window.addEventListener('scroll', callBack)
    return () => window.removeEventListener('scroll', callBack)
  })
}