/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useEffect, useRef, useState } from 'react'
import { Link, graphql } from 'gatsby'
// https://codebushi.com/gatsby-featured-images/
import Img from 'gatsby-image'
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CTA from '../components/CTA'
import PostFeedbackModal from '../components/PostFeedbackModal'
import { formatDate } from '../lib/Utils'
import AnchorLink from '../components/AnchorLink'
import { useLocation } from '@reach/router'
import BackgroundImage from 'gatsby-background-image'
import { useModal } from '../sections/Modal'
import { useMarketing } from '../lib/Marketing'
import useScrollPosition from '../lib/ScrollPosition'
import Cookies from '../lib/Cookies'
import '../styles/Prose'

const BlogPost = function({pageContext: { author }, data: { mainPost, previousPost, nextPost}}) {  
  const { title, description, created, image } = mainPost.frontmatter
  const encodedTitle = encodeURIComponent(title)
  const cookieFeedbackName = useRef('mt_' + title.toLowerCase().replace(/[\s+\-]+/g,'_').replace(/\.\,/g,''))
  const cookieFeedbackValue = useRef()
  const { isRegistered } = useMarketing()
  const previous = previousPost?.frontmatter
  const next = nextPost?.frontmatter
  const { href:url } = useLocation()
  const root = useClasses()
  const modal = useModal()
  const [ modalShown, setModalShown ] = useState(false)

  useEffect(() => {
    var value = Cookies.getItem(cookieFeedbackName.current)
    if (value) cookieFeedbackValue.current = new Date(parseInt(value))
  }, [])

  useScrollPosition(({current, previous}) => {
    if (isRegistered || modalShown) return 
    if (previous != 0 && current > 1400) {
      modal.show({
        keepOpen: true,
        component:PostFeedbackModal, 
        data: {
          title,
          getEmail: !isRegistered
        }
      })
      setModalShown(true)
      cookieFeedbackValue.current = new Date().getTime()
      Cookies.setItem(cookieFeedbackName.current, cookieFeedbackValue.current)
    }
  }, [isRegistered, modalShown])

  return (
    <Layout>
      <SEO 
        title={title}
        description={description}
        image={image.childImageSharp.fluid.src}
        article
      />
      <div className='main' ref={root}>
        <div className='relative'>
          <Img className='rounded w-full min-h-100' fluid={image.childImageSharp.fluid} alt={title}/>
          <div className='w-full bg-white opacity-90 h-32 -mt-32 lg:h-28 lg:-mt-28 pt-3 px-2 flex flex-col gap-1 items-center'>
            <h1 className='w-full leading-tight tracking-tight text-center text-xl md:text-2xl lg:text-3xl font-black'>{title}</h1>
            {author && <span className="text-sm">By <AnchorLink to='#author'><strong>{author.name}</strong></AnchorLink></span>}
          </div>
          <div className='absolute right-2 bottom-2 flex flow-row gap-x-2 pr-2 justify-center items-center'>
            <span className='text-sm text-gray-600 mr-2'>{formatDate(created)}</span>
            <span className='hidden md:block text-sm font-bold text-gray-600'>Share</span>
            <a href={'https://www.facebook.com/sharer/sharer.php?u='+url+'&p[title]='+encodedTitle} target='_blank' rel='noopener noreferrer'>
              <svg className='h-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 455.73 455.73">
                <path fill="#3A559F" d="M0,0v455.73h242.704V279.691h-59.33v-71.864h59.33v-60.353c0-43.893,35.582-79.475,79.475-79.475 h62.025v64.622h-44.382c-13.947,0-25.254,11.307-25.254,25.254v49.953h68.521l-9.47,71.864h-59.051V455.73H455.73V0H0z"/>
              </svg>
            </a>
            <a href={'https://twitter.com/share?text='+encodedTitle+'&url='+url+'&hashtags=OneOffice'} target='_blank' rel='noopener noreferrer'>
              <svg className='w-6 h-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 231.104 231.104">
                <path fill="#3FA9F5" d="M4.453,173.33c9.763-1.192,19.663,0.092,29.426-1.512c4.904-0.779,9.396-2.429,13.842-4.171 		c-11-7.058-20.901-15.125-30.113-24.796c-3.3-3.438-0.917-9.213,3.896-9.35c3.942,0.183,7.792-0.137,11.55-0.917 	c-9.58-12.146-17.005-25.209-22.78-39.876c-1.558-3.942,3.025-7.929,6.738-6.738c2.154,0.871,4.354,1.467,6.6,1.925 	c-6.829-16.409-8.25-32.955-4.446-51.106c0.871-4.171,6.371-5.179,9.167-2.429c21.909,21.541,49.593,31.9,79.202,36.85 	c-2.613-20.259,11.78-41.801,30.663-48.86c15.676-5.821,36.714-1.833,47.256,11.367c7.059-4.446,16.821-5.913,24.659-7.288 	c4.125-0.688,8.113,3.346,5.684,7.425c-2.842,4.767-5.546,9.854-8.525,14.713c6.05-1.788,12.284-2.888,18.517-3.667 	c4.492-0.596,7.196,6.325,3.759,9.075c-7.288,5.821-14.53,12.467-22.276,17.784c-0.229,51.472-15.263,94.649-61.235,123.937 	c-38.319,24.477-109.546,20.352-142.867-12.97H3.124c-1.467-0.367-2.246-1.467-2.521-2.658c-1.283-1.925-0.367-4.308,1.329-5.225 C2.574,174.063,3.399,173.467,4.453,173.33z"/>
              </svg>
            </a>
            <a href={'https://pinterest.com/pin/create/button/?url='+url+'&description='+encodedTitle} target='_blank' rel='noopener noreferrer'>
              <svg className='w-6 h-6' viewBox="0 0 473.93 473.93" xmlns="http://www.w3.org/2000/svg">
                <circle cx="236.97" cy="236.97" r="236.97" fill="#d42028"/>
                <path d="m173.43 123.06c-35.622 14.11-59.816 41.29-66.577 77.903-4.15 22.499-2.574 47.023 7.353 68.029 2.215 4.666 4.868 9.164 8.026 13.321 1.53 2.028 3.173 3.966 4.954 5.807 3.162-1.13 6.211-2.537 9.122-4.183 12.041-6.795 20.924-17.268 31.236-26.065-34.469-39.932 2.058-89.409 47.749-100.44 42.536-10.252 100.19 7.442 113.09 51.21 5.336 18.125 1.841 38.162-12.939 51.285-7.727 6.87-17.541 11.409-27.914 13.257-6.058 1.074-12.277 1.242-18.402 0.644-3.413-0.344-6.803-0.921-10.144-1.706-5.658-1.332-10.69-1.137-10.69-7.24v-71.779c0-4.89 0.479-3.97-3.577-4.471-3.203-0.393-6.398-0.703-9.605-0.958-11.285-0.883-23.083-1.489-34.275 0.438-4.187 0.722-4.453 0.049-4.453 4.591v37.848c0 26.724 2.013 53.762 0.909 80.444-0.322 7.951-0.543 27.034-9.44 30.971-10.312 4.561-19.431-5.085-28.946-7.094 1.31 13.396-7.072 39.932 8.542 46.693 14.316 6.196 30.791 8.505 45.881 3.386 31.622-10.724 41.848-45.915 37.84-74.831 48.811 14.585 101.5-9.815 122.37-53.889 14.866-31.386 8.587-70.368-13.792-97.054-41.918-50.003-126.99-59.589-186.31-36.117z" fill="#fff"/>
              </svg>
            </a>
            <a href={'https://www.linkedin.com/shareArticle?url='+url+'&title='+encodedTitle} target='_blank' rel='noopener noreferrer'>
              <svg className='fill-current w-6 h-6 text-blue-600' viewBox="0 0 310 310" xmlns="http://www.w3.org/2000/svg">
                <path d="m72.16 99.73h-62.233c-2.762 0-5 2.239-5 5v199.93c0 2.762 2.238 5 5 5h62.233c2.762 0 5-2.238 5-5v-199.93c0-2.761-2.238-5-5-5z"/>
                <path d="M 41.066,0.341 C 18.422,0.341 0,18.743 0,41.362 0,63.991 18.422,82.4 41.066,82.4 63.692,82.4 82.099,63.99 82.099,41.362 82.1,18.743 63.692,0.341 41.066,0.341 Z"/>
                <path d="m230.45 94.761c-24.995 0-43.472 10.745-54.679 22.954v-12.985c0-2.761-2.238-5-5-5h-59.599c-2.762 0-5 2.239-5 5v199.93c0 2.762 2.238 5 5 5h62.097c2.762 0 5-2.238 5-5v-98.918c0-33.333 9.054-46.319 32.29-46.319 25.306 0 27.317 20.818 27.317 48.034v97.204c0 2.762 2.238 5 5 5h62.12c2.762 0 5-2.238 5-5v-109.66c0-49.565-9.451-100.23-79.546-100.23z"/>
              </svg>
            </a>
            <a href={'mailto:?Subject='+encodedTitle+'&Body='+url} target='_blank' rel='noopener noreferrer'>
              <svg className='h-8 text-primary-dark' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.048 294.048">
                <path fill="currentColor" d="M294.048,56.386c0-8.284-6.716-15-15-15H15c-8.284,0-15,6.716-15,15v181.276c0,8.284,6.716,15,15,15h264.048 c8.284,0,15-6.716,15-15V56.386z M264.048,86.936c0,4.415-1.937,8.607-5.298,11.469l-57.52,48.976l57.575,49.023 c3.327,2.832,5.243,6.982,5.243,11.351c0,8.233-6.674,14.908-14.908,14.908h-0.128c-3.643,0-7.168-1.297-9.942-3.659l-60.979-51.922 l-21.343,18.172c-2.803,2.386-6.264,3.579-9.725,3.579c-3.461,0-6.922-1.193-9.725-3.579l-21.343-18.172L55.075,218.92 	c-2.837,2.416-6.442,3.742-10.168,3.742c-8.233,0-14.908-6.674-14.908-14.908c0-4.369,1.917-8.518,5.243-11.351l57.576-49.023 	L36.094,99.082C32.228,95.79,30,90.967,30,85.889c0-8.01,6.493-14.504,14.504-14.504c3.446,0,6.779,1.227,9.403,3.461l88.376,75.248 	c2.733,2.327,6.751,2.327,9.485,0l88.375-75.248c2.624-2.234,5.957-3.461,9.402-3.461c8.01,0,14.503,6.493,14.503,14.503V86.936z"/>
              </svg>
            </a>
          </div>
        </div>

        <div className='prose' dangerouslySetInnerHTML={{ __html: mainPost.html }} />

        <div className='flex gap-3 mt-8 items-center' id='author'>
          <img className='w-12 h-12 rounded-full' src={'/authors/'+author.id+'.jpg'} />
          <div className='text-xs'>
            <div className='text-gray-200 font-bold text-gray-900 text-sm'>{author.name}</div>
            <div className='pt-1 font-medium text-gray-600 group-hover:text-gray-200'>{author.description}</div>
          </div>
        </div>

        <CTA/>

        <div className='flex flex-col md:flex-row justify-between gap-y-4 w-full mt-10 mb-10'>
          { next ?
            <Link to={'/blog/' + next.slug} className='bg-indigo-700 hover:bg-indigo-900 group flex flex-row w-full md:w-2/5 lg:w-3/7 overflow-hidden rounded h-32'>
              <BackgroundImage className='relative w-2/5 h-full flex justify-center items-center' fluid={next.image.childImageSharp.fluid} alt={next.title}>
                <div className='z-0 absolute w-full h-full opacity-30 group-hover:opacity-50 bg-black'/>
                <svg xmlns="http://www.w3.org/2000/svg" className="z-1 h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
              </BackgroundImage>
              <div className='w-3/5 h-full mx-3 flex flex-col justify-center'>
                <h2 className='text-xl font-bold text-white tracking-tight leading-none mb-2'>{next.title}</h2>
                <p className='font-light text-sm leading-tight text-gray-100' dangerouslySetInnerHTML={{__html: next.description}}/>
              </div>
            </Link> : <div/>
          }
          { previous ?
            <Link to={'/blog/' + previous.slug} className='bg-indigo-700 hover:bg-indigo-900 group flex flex-row w-full md:w-2/5 lg:w-3/7 overflow-hidden rounded h-32'>
              <div className='w-3/5 h-full mx-3 flex flex-col justify-center'>
                <h2 className='text-xl font-bold text-white tracking-tight leading-none mb-2'>{previous.title}</h2>
                <p className='font-light text-sm leading-tight text-gray-100' dangerouslySetInnerHTML={{__html: previous.description}}/>
              </div>
              <BackgroundImage className='relative w-2/5 h-full flex justify-center items-center' fluid={previous.image.childImageSharp.fluid} alt={previous.title}>
                <div className='z-0 absolute w-full h-full opacity-30 group-hover:opacity-50 bg-black'/>
                <svg xmlns="http://www.w3.org/2000/svg" className="z-1 h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </BackgroundImage>
            </Link> : null
          }
        </div>

      </div>
    </Layout>
  )
}

export default BlogPost

export const postQuery = graphql`
  query BlogPostsBySlug($slug: String!, $previous: String!, $next: String!) {
    mainPost: markdownRemark(
      frontmatter: { slug: { eq: $slug } },
      fileAbsolutePath: { glob: "**/src/posts/**/*.md" }
    ) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        created
        revised
        description
        slug
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    previousPost: markdownRemark(
      frontmatter: { slug: { eq: $previous } },
      fileAbsolutePath: { glob: "**/src/posts/**/*.md" }
    ) {
      id
      frontmatter {
        title
        created(formatString: "MMMM DD, YYYY")
        description
        slug
        image {
          childImageSharp {
            fluid(maxWidth: 630, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    nextPost: markdownRemark(
      frontmatter: { slug: { eq: $next } },
      fileAbsolutePath: { glob: "**/src/posts/**/*.md" }
    ) {
      id
      frontmatter {
        title
        created(formatString: "MMMM DD, YYYY")
        description
        slug
        image {
          childImageSharp {
            fluid(maxWidth: 630, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
