import React, { useEffect, useState } from 'react'
import { useClasses, clearForm } from '../lib/ReactUtils'
import { useModal } from '../sections/Modal'
import { useToast } from '../sections/Toast'
import PostNewsletter from '../lib/PostNewsletter'
import Loader from './Loader'


export default function PostFeedbackModal({title, getEmail}) {
  const root = useClasses()
  const modal = useModal()
  const toast = useToast()
  const [feedback, setFeedback] = useState(null)
  const [errors, setErrors] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [result, setResult] = useState(null)

  useEffect(() => {
    // we hide modal after feedback if we know who it is -- to be added: sending feedback to mautic
    if (result || (feedback !== null && !getEmail)) setTimeout(modal.hide, 3000)
  }, [feedback, getEmail])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const _errors = []
    const target = e.currentTarget
    const email = target[0].value.replace(/\s/g,'')
    if (email.length == 0) _errors.push('Please enter your email')
    else if (email.length < 5 || !email.includes('@')) _errors.push('Invalid email')
    setErrors(_errors)
    setResult(null)
    if (_errors.length) return
    setSubmitting(true)
    const message = "Article: " + title + " -- Feedback: " + (feedback ? "Positive" : "Negative")
    const result = await PostNewsletter({email, message})
    setSubmitting(false)
    if (result) toast.show('Success! Thank you for your feedback', 'success')
    else toast.show('Failed! Sorry, please email us at info@oneoffice.ca', 'error')
    clearForm(target)
    setResult(result)
    modal.hide()
  }

  return (
    <div ref={root} className='flex flex-col items-center gap-4'>
      <h3 className='font-bold text-lg lg:text-xl text-center'>Do you like this article?</h3>
      <div className='flex flex-row justify-center gap-x-10'>
        <svg
          className={'border-4 p-3 rounded h-30 fill-current ' + (feedback === true ? 'border-green-700 text-green-700' : 'border-transparent cursor-pointer text-green-500 hover:text-green-700 transition duration-300')}
          viewBox="0 0 512.18 711.14"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setFeedback(true)}
        >
          <path d="m104.18 224h-80c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24v-240c0-13.255-10.745-24-24-24zm-40 248c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zm320-390.55c0 42.416-25.97 66.208-33.277 94.548h101.72c33.397 0 59.397 27.746 59.553 58.098 0.084 17.938-7.546 37.249-19.439 49.197l-0.11 0.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-0.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632-20.41 29.324-70.996 29.737-113.77 29.737l-2.845-1e-3c-48.287-0.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-0.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.11 14.804-14.832 20.188-37.236 25.393-58.902 4.447-18.501 13.749-57.794 33.932-57.794 24 0 72 8 72 81.452z"/>        </svg>
        <svg
          className={'border-4 p-3 rounded h-30 fill-current ' + (feedback === false ? 'border-red-700 text-red-700' : 'border-transparent cursor-pointer text-red-500 hover:text-red-700 transition duration-300')}
          viewBox="0 0 512.18 711.14"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setFeedback(false)}
        >
          <path d="m104 223.14h-80c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24v-240c0-13.255-10.745-24-24-24zm-40 248c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z"/>
          <path d="m384 629.69c0-42.416-25.97-66.208-33.277-94.548h101.72c33.397 0 59.397-27.746 59.553-58.098 0.084-17.938-7.546-37.249-19.439-49.197l-0.11-0.11c9.836-23.337 8.237-56.037-9.308-79.469 8.681-25.895-0.069-57.704-16.382-74.757 4.298-17.598 2.244-32.575-6.148-44.632-20.41-29.324-70.996-29.737-113.77-29.737l-2.845 1e-3c-48.287 0.017-87.806 17.598-119.56 31.725-15.957 7.099-36.821 15.887-52.651 16.178-6.54 0.12-11.783 5.457-11.783 11.998v213.77c0 3.2 1.282 6.271 3.558 8.521 39.614 39.144 56.648 80.587 89.117 113.11 14.804 14.832 20.188 37.236 25.393 58.902 4.447 18.501 13.749 57.794 33.932 57.794 24 0 72-8 72-81.452z"/>        </svg>
      </div>
      { feedback == null ?
        <p className='text-xs md:text-sm text-gray-600 leading-snug px-4'>
          Our content authors are our team, your feedback will help us improve and write about stuff you are interested in.
        </p> :
        <>
          <p className='text-sm text-gray-600 leading-snug'>
            { feedback ? "Thank you! We'll make sure to write more like this" : "Ouch! Sorry it wasn't up to your expecations" }
          </p>

          { getEmail && 
            <>
              <p className='text-sm text-center text-primary-dark leading-snug mt-4 mb-1'>
                Please share your email for more interesting articles!
              </p>
              <div className='flex items-center'>
                <form className='basic w-full flex flex-col md:flex-row gap-2' onSubmit={handleSubmit}>
                  <div className='relative'>
                    <input type='email' placeholder=' ' name='email' disabled={submitting}/>
                    <label htmlFor='email'>Email</label>
                    { errors.map((e, index) => <div key={index} className='error'>{e}</div>) }
                  </div>
                  <button type='submit' className={'button tight mt-1 md:mt-0 w-full md:w-auto ' + (errors.length ? 'md:mb-6' : 'mb-1')}>
                    {submitting ? <Loader className='inline'/> : 'Submit'}
                  </button>
                </form>
              </div>
            </>
           }
        </>
      }
    </div>
  )
}
